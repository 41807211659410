<template>
  <div>
    <vx-card card-background="primary" content-color="white" title-color="white" title="Tu información es de suma importancia.">
      <div class="vx-col w-full">
        <p>Este apartado se compone de tus datos generales, verificación de identidad y otros puntos importantes que nos permiten conocerte y tener una Red más segura.
          Procura mantener tu información lo más actualizada posible.</p>
      </div>
    </vx-card>

    <vx-card class="mt-base">
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mb-4">
            <div class="flex flex-wrap items-center">
              <p class="card-sub-title mr-5">
                Sobre ti.
              </p>
            </div>
          </div>
          <p>
            Una vez completada tu verificación de identidad podrás consultar en esta sección tus datos y documentación general.
            <span class="bold">Completa este apartado lo antes posible, ya que de lo contrario no podrás continuar participando en la Red.</span>
          </p>
        </div>
      </div>
      <div class="vx-row mt-3" >
        <div class="vx-col w-full">
          <vs-button class="ml-auto mt-2" @click.stop="goToAboutYou">Actualizar</vs-button>
        </div>
      </div>
    </vx-card>

    <vx-card class="mt-base">
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mb-4">
            <div class="flex flex-wrap items-center">
              <p class="card-sub-title mr-5">
                Datos Generales.
              </p>
            </div>
          </div>
          <p>Mantén tus datos actualizados. Ingresa en este apartado para llenar la información requerida.</p>
        </div>
      </div>
      <div class="vx-row mt-3" >
        <div class="vx-col w-full">
          <vs-button class="ml-auto mt-2" @click="goToEditProfile">Actualizar</vs-button>
        </div>
      </div>
    </vx-card>

    <!--vx-card class="mt-base">
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mb-4">
            <div class="flex flex-wrap items-center">
              <p class="card-sub-title mr-5">
                Queremos conocerte.
              </p>
              <vs-button type="border" class="px-3 py-1" @click="goToKycPage">Editar</vs-button>
            </div>
          </div>
          <p>Sección no habilitada.</p>
        </div>
      </div>
    </vx-card-->

    <vx-card v-if="userHasTaxProfile" class="mt-5">
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mb-4">
            <div class="flex flex-wrap items-center">
              <p class="card-sub-title mr-5">
                Perfil fiscal.
              </p>
            </div>
          </div>
          <p>
            Es importante que registres tus datos fiscales (RFC, Nombre, Código Postal y régimen).
          </p>
        </div>
      </div>
      <div class="vx-row mt-3" >
        <div class="vx-col w-full">
          <vs-button class="ml-auto mt-2" @click.stop="goToTaxProfile">Actualizar</vs-button>
        </div>
      </div>
    </vx-card>

    <mati-popup :user-id-to-activate="UserId" @on-mati-process-started="onMatiProcessStarted" />

  </div>
</template>

<script>
import MatiPopup from "@components/mati/MatiPopup";
export default {
  name: "ApplicantProfile",
  components: {
    MatiPopup
  },
  data() {
    return {
      nationalPmPersonType: 0,
      nationalPfPersonType: 1,
      isMounted: false,
      identity: null,
      status: null,
      processWasStarted: false,
    }
  },
  async mounted(){
    // this.isMounted = false
    // this.showLoading(true)
    this.isMounted = true
    // this.showLoading(false)
  },
  computed: {
    shouldRunMatiProcess(){
      return this.status === 0 || this.status === 4 ||this.status === 5;
    },
    verificationIsCompleted(){
      return this.status === 1;
    },
    verificationIsRejected(){
      return this.status === 4;
    },
    verificationIsInProcess(){
      return  this.status === 3;
    },
    verificationisNewRequired(){
      return this.status === 5;
    },
    verificationIsReviewNeeded(){
      return this.status === 2;
    },
    userHasTaxProfile(){
      return (this.UserPersonType == this.nationalPfPersonType || this.UserPersonType == this.nationalPmPersonType);
    },
  },
  methods: {
    async reloadStatus(){
      this.showLoading(true)
      this.processWasStarted = false;
      this.showLoading(false)
    },
    async onMatiProcessStarted(){
      this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", false);
      this.processWasStarted = true;
    },
    async goToAboutYou(){
      await this.$router.push({name: 'perfilSolicitanteAbout'});
    },
    async goToEditProfile(){
      await this.$router.push({name: 'perfilSolicitanteGeneral'});
    },
    async goToKycPage(){
      await this.$router.push({name: 'kycSolicitante'});
    },
    async openMatiPopup(){
      this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", true);
    },
    async goToTaxProfile(){
      await this.$router.push({name: 'perfilFiscalSolicitante'});
    },
  }
}
</script>

<style scoped>
 .card-disabled{
   opacity: 0.4;
 }
</style>