<template>
  <div>
    <big-title-color variant-class="dark">
      <template v-slot:text>Mi <span>Perfil</span></template>.
    </big-title-color> 

    <vs-tabs v-model="activeTab" class="mt-5 tab-action-btn-fill-container profile-tabs">
      <vs-tab label="Mi información" icon-pack="feather" icon="icon-user" >
        <applicant-profile />
      </vs-tab>

      <vs-tab label="Mi cuenta" icon-pack="feather" icon="icon-bookmark" >
        <account-page />
      </vs-tab>

      <vs-tab label="Mis contratos" icon-pack="feather" icon="icon-archive" >
        <contracts-page />
      </vs-tab>

      <vs-tab label="Seguridad" icon-pack="feather" icon="icon-shield" >
        <security-page />
      </vs-tab>

      <vs-tab label="Notificaciones" icon-pack="feather" icon="icon-mail">
        <NotificationsPage/>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import ApplicantProfile from "@views/applicant/profile/ApplicantProfile";
import AccountPage from "@views/applicant/profile/AccountPage";
import NotificationsPage from "@views/applicant/profile/NotificationsPage";
import SecurityPage from "@views/applicant/security/SecurityPage";
import ContractsPage from "@views/applicant/contracts/ContractsPage";
import CsdsPage from "../csds/CsdsPage";
import MonthlyRetentionPage from "../monthly-retentions/MonthlyRetentionPage";
import {mapState} from "vuex";

export default {
  name: "ProfilePage",
  props: {
    'tab_index': {
      type: Number,
      default: null,
      require: true,
    }
  },
  data(){
    return {
      activeTab: 0,
      applicant: {},
    }
  },
  components: {
    MonthlyRetentionPage,
    CsdsPage,
    ApplicantProfile,
    AccountPage,
    SecurityPage,
    ContractsPage,
    NotificationsPage
  },
  beforeMount() {
    this.activeTab = this.tab_index ?? 0;
  }
}
</script>

<style >
.profile-tabs .vs-tabs--li button {
  font-family: 'Gilroybold',sans-serif !important;
  font-weight: normal !important;
}
</style>